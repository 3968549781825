<footer>

    <!-- Copyright -->
  <div class="text-center p-3 footer container-fluid">
    
    <div class="col-sm-12 copyright">
        <div>
            © 2021 Copyright: Metcalf Tree Service
        </div>
        <a href="tel:5025337066">
            Contact: (502)533-7066
        </a>
    </div>
    

  </div>

</footer>