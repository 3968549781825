<!-- navbar component -->
<header>
    <nav class="navbar nav-container">
      <div class="row container-fluid ">

        <div class="col-sm-4 align-self-center text-center trees" id="upperTrees">
          <fa-icon [icon]="faTree" class="fa-3x m-2"></fa-icon>
          <fa-icon [icon]="faTree" class="fa-4x m-2"></fa-icon>
          <fa-icon [icon]="faTree" class="fa-6x m-2"></fa-icon>
        </div>

        <div class="col-sm-4 align-self-center text-center">
          <h1 class="text-white logo">Metcalf Tree Service</h1>
          <a href="tel:5025337066" class="text-white logo">(502)533-7066</a>
          <h3 class="text-white logo">Call today for a free quote</h3>
        </div>
        
        <div class="col-sm-4 align-self-center text-center trees">
          <fa-icon [icon]="faTree" class="fa-6x m-2"></fa-icon>
          <fa-icon [icon]="faTree" class="fa-4x m-2"></fa-icon>
          <fa-icon [icon]="faTree" class="fa-3x m-2"></fa-icon>
        </div>
        
      </div>
      
    </nav>
  </header>