<!-- cards -->
<div class="container mt-3">
    <div class="row">
  
    <!-- Pruning card -->
      <div class="col-lg-6 col-sm-12 mb-4 grid-margin">
        <div class="card h-100">
            <h4 class="card-header text-white">Pruning</h4>
            <img class="card-img-top" src="../../../assets/imgs/pruning.jpg" alt="Picture of Pruning">
            <div class="card-body">
              <p class="card-text">Prune trees for overall health and longevity.</p>
            </div>
            <div class="card-footer">
              <a href="tel:5025337066" class="btn btn-primary">Call for more information</a>
            </div>
        </div>
      </div>
  
      <!-- Removal card -->
      <div class="col-lg-6 col-sm-12 mb-4 grid-margin">
        <div class="card h-100">
            <h4 class="card-header text-white">Removal</h4>
            <img class="card-img-top" src="../../../assets/imgs/stormDamage.jpg" alt="Picture of tree Removal">
            <div class="card-body">
              <p class="card-text">Remove hazardous and storm damaged trees for your safety.</p>
            </div>
            <div class="card-footer">
              <a href="tel:5025337066" class="btn btn-primary">Call for more information</a>
            </div>
        </div>
      </div>

      <!-- Stump grinding card -->
      <div class="col-lg-6 col-sm-12 mb-4 grid-margin">
        <div class="card h-100">
            <h4 class="card-header text-white">Stump Grinding</h4>
            <img class="card-img-top" src="../../../assets/imgs/stumper.jpg" alt="Picture of stump grinding">
            <div class="card-body">
              <p class="card-text">Stump Removal</p>
            </div>
            <div class="card-footer">
              <a href="tel:5025337066" class="btn btn-primary">Call for more information</a>
            </div>
        </div>
      </div>
  
      <!-- Cable and bracing card -->
      <div class="col-lg-6 col-sm-12 mb-4 grid-margin">
        <div class="card h-100">
            <h4 class="card-header text-white">Cable and Bracing</h4>
            <img class="card-img-top" src="..." alt="Picture of cabling">
            <div class="card-body">
              <p class="card-text">Support unfavorable tree conditions to promote longevity.</p>
            </div>
            <div class="card-footer">
              <a href="tel:5025337066" class="btn btn-primary">Call for more information</a>
            </div>
        </div>
      </div>
  
  </div>